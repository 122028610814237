/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useMemo } from 'react';
import { FormBuilderPlaceholder } from '../components/form-builder/FormBuilderTypes';
import { SupportedLanguage, systemDefaultLanguageCode } from '../types/Languages';
import { FormRendererFeatureToggles, isActionResponseValid } from '../components/form/renderer/FormRendererV2';
import { ClientForm, ClientFormRequest } from '../models/ClientForm';
import { ActionHighlightInfo, ActionPlaceholderData, AdHocAnswerResponse, FormAction, FormConfig, FormSection, NumberingLevel } from '../models/Form';
import { FormRendererMode } from './FormRendererDesignContextTypes';
import { Risk } from '../models/Risk';
import { ActionTypeNames } from '../components/form/ActionTypes';
import { toRecord } from '../utils/ListUtils';

export enum FormRendererType {
  Platform,
  Public,
}

export type FormRendererContextType = {
  clientFormId?: string;
  clientForm?: ClientForm;
  mode: FormRendererMode;
  type: FormRendererType;
  changeMode: (newMode: FormRendererMode, stepId?: string) => void;
  readOnly: boolean;
  config: FormConfig;
  containingTemplateId: string;
  containingModuleId?: string;
  containingModuleSectionId?: string;
  isDisplayedInline: boolean;
  validQuestions: Record<string, boolean>;
  renderMinimal: boolean;
  rootFormSection: FormSection;
  visibleActions: Record<string, boolean>;
  visibleActionsList: string[];
  actionLookup: Record<string, FormAction>;
  rootContext?: FormRendererContextType;
  questionAnswers: Record<string, any>;
  placeholders: Record<string, ActionPlaceholderData>;
  actionRisks?: Record<string, number>;
  allFlaggedRisks?: Risk[];
  featureToggles?: FormRendererFeatureToggles;
  requiredActions: Record<string, boolean>;
  toHighlight: ActionHighlightInfo | null;
  language: SupportedLanguage;
  onAnswer: (questionId: string, answer: any) => Promise<void>;
  createForm: (data: ClientFormRequest) => Promise<ClientForm | null>;
  createFormBulk: (data: ClientFormRequest[]) => Promise<(ClientForm | null)[]>;
  setQuestionValid: (id: string, valid?: boolean) => void;
  refetchProgress: () => void;
  isCommentsVisible?: boolean;
  answersBusySaving: Record<string, boolean>;
  createPlaceholder: (value: FormBuilderPlaceholder) => void;
  deletePlaceholder: (actionId: string, value: string) => void;
  adHocAnswers: Record<string, AdHocAnswerResponse[]>;
  onAdHocAnswer: (actionId: string, fieldId: string, payload: AdHocAnswerPayload) => Promise<void>;
  sortAdHocAnswers: (actionId: string, fieldIds: string[]) => void;
  removeAdHocAnswer: (actionId: string, fieldId: string) => Promise<void>;
};

export type AdHocAnswerPayload = {
  data?: any;
  questionData?: any & { type: ActionTypeNames };
  sortOrder?: number;
  numberingLevel?: NumberingLevel;
};

const NOOP = (): never => {
  throw new Error('Cannot use outside FormRendererInfoContext');
};

export const EmptyFormRendererInfoValues: FormRendererContextType = {
  clientFormId: null!,
  clientForm: null!,
  mode: FormRendererMode.EditView,
  changeMode: () => ({}),
  readOnly: false,
  config: null!,
  containingTemplateId: '',
  containingModuleId: '',
  containingModuleSectionId: '',
  isDisplayedInline: false,
  validQuestions: {},
  renderMinimal: false,
  rootContext: undefined,
  rootFormSection: null!,
  visibleActions: {},
  visibleActionsList: [],
  questionAnswers: {},
  placeholders: {},
  actionRisks: {},
  featureToggles: {},
  actionLookup: {},
  requiredActions: {},
  toHighlight: null,
  language: systemDefaultLanguageCode,
  onAnswer: NOOP,
  createForm: NOOP,
  createFormBulk: NOOP,
  setQuestionValid: NOOP,
  refetchProgress: NOOP,
  isCommentsVisible: false,
  answersBusySaving: {},
  createPlaceholder: NOOP,
  deletePlaceholder: NOOP,
  adHocAnswers: {},
  onAdHocAnswer: NOOP,
  sortAdHocAnswers: NOOP,
  removeAdHocAnswer: NOOP,
  type: FormRendererType.Platform,
};

export const useCreateReadonlyFormRendererInfoValues = (form: FormConfig): Partial<FormRendererContextType> =>
  useMemo(() => {
    const actionLookup: Record<string, FormAction> = {};
    const visibleActions: Record<string, boolean> = {};
    const visibleActionsList: string[] = [];
    const requiredActions: Record<string, boolean> = {};
    const validQuestions: Record<string, boolean> = {};

    for (const section of form.sections) {
      for (const action of section.actions) {
        actionLookup[action.id] = action;
        visibleActions[action.id] = !!action.visible;
        requiredActions[action.id] = !!action.required;
        validQuestions[action.id] = !!form.answers && isActionResponseValid(form.answers[action.id]);

        if (action.visible) {
          visibleActionsList.push(action.id);
        }
      }
    }

    return {
      config: form,
      containingTemplateId: form.id,
      questionAnswers: form.answers,
      validQuestions: {},
      visibleActions: visibleActions,
      visibleActionsList: visibleActionsList,
      actionLookup: actionLookup,
      requiredActions: requiredActions,
      placeholders: form.placeholders
        ? toRecord(
            form.placeholders.map(
              (x) =>
                ({
                  placeholder: x.placeholder,
                  actionType: 'DateAction', // value doesn't matter, but need to provide it to satisfy type
                  translations: x.translations,
                  friendlyName: x.friendlyName,
                  dataFormat: x.dataFormat,
                  answers: [],
                  clientFormIds: [],
                  actionIds: [],
                }) as ActionPlaceholderData & { placeholder: string },
            ),
            'placeholder',
          )
        : {},
    };
  }, [form]);

export const FormRendererInfoContext = createContext<FormRendererContextType>(EmptyFormRendererInfoValues);
export const useFormRendererInfo = (): FormRendererContextType => useContext(FormRendererInfoContext);
