/* eslint-disable @typescript-eslint/no-explicit-any */
import i18next from 'i18next';
import { forwardRef, ForwardRefRenderFunction, Suspense, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  AdHocAnswerPayload,
  FormRendererContextType,
  FormRendererInfoContext,
  FormRendererType,
  useFormRendererInfo,
} from '../../../contexts/FormRendererContext';
import { FormRendererMode } from '../../../contexts/FormRendererDesignContextTypes';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import { EventSystem } from '../../../events/EventSystem';
import { ClientForm, ClientFormRequest, ClientFormSectionInfo } from '../../../models/ClientForm';
import { ClientFormSectionStatus } from '../../../models/ClientFormSectionStatus';
import {
  ActionHighlightInfo,
  ActionPlaceholderData,
  AdHocAnswerResponse,
  AnswerResponse,
  FormAction,
  FormAnswerResponse,
  FormConfig,
  NumberingLevel,
} from '../../../models/Form';
import { FormTypeKeys } from '../../../models/FormTypes';
import { WorkflowTriggerResponse } from '../../../models/WorkflowTriggerResponse';
import ClientFormService from '../../../services/ClientFormService';
import ClientPublicFormService from '../../../services/ClientPublicFormService';
import FormResponseService from '../../../services/FormResponseService';
import { nextTick } from '../../../utils/ReactUtils';
import { SupportedLanguage } from '../../../types/Languages';
import InfoIcon from '../../shared/icon/InfoIcon';
import PageLoader from '../../shared/page-loader/PageLoader';
import { Heading, HeadingSize } from '../../shared/text/Heading';
import FormSectionRendererV2 from './FromSectionRendererV2';
import { FormBuilderPlaceholder } from '../../form-builder/FormBuilderTypes';
import { toRecord } from '../../../utils/ListUtils';
import { ActionTypeNames } from '../ActionTypes';
import { Risk } from '../../../models/Risk';
import { ApiResponse } from '../../../models/ApiResponse';
import { v4 as uuid } from 'uuid';

const EMPTY_ARRAY: never[] = [];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isActionResponseValid = (response: any): boolean =>
  Array.isArray(response) ? response.length > 0 : response !== null && response !== undefined && response !== '';

export type FormRendererFeatureToggles = {
  disableModeToggle?: boolean;
  disableSectionHeading?: boolean;
  disableAutoAnswerSubmit?: boolean;
  enableTotalComments?: boolean;
  disableQuestionCommentIcon?: boolean;
  enableInlineTitleEdit?: boolean;
};

type FormRendererV2Props = {
  initialMode: FormRendererMode;
  stepRendererModes?: Record<string, FormRendererMode>;
  canEdit?: boolean;
  moduleId?: string;
  moduleSectionId?: string;
  actionRisks?: Record<string, number>;
  allFlaggedRisks?: Risk[];
  renderAllSteps?: boolean;
  featureToggles?: FormRendererFeatureToggles;
  stepStatuses?: ClientFormSectionInfo[];
  languageOverride?: SupportedLanguage;
  actionFilter?: (action: FormAction) => boolean;
  isCommentsVisible?: boolean;
  number?: string;
  type?: FormRendererType;
} & (
  | {
      clientForm: ClientForm;
    }
  | {
      form: FormConfig;
    }
);

type ForwardRefHandle = {
  changeStep: (index: number) => void;
  setMode: (newMode: FormRendererMode) => void;
  highlight: (toHighlight: ActionHighlightInfo | null) => void;
  pushState: (
    questions: Record<string, FormAction>,
    answers: Record<string, any>,
    placeholders: Record<string, any>,
    visibleActions: Record<string, boolean>,
    requiredActions: Record<string, boolean>,
  ) => void;
  onStatusChanged: (triggerResponse: WorkflowTriggerResponse) => void;
};

const FormRendererV2: ForwardRefRenderFunction<ForwardRefHandle, FormRendererV2Props> = (props, ref) => {
  const {
    initialMode,
    stepRendererModes,
    canEdit,
    moduleId,
    moduleSectionId,
    actionFilter,
    actionRisks,
    renderAllSteps,
    stepStatuses,
    featureToggles,
    languageOverride = i18next.language,
    isCommentsVisible,
    number = '',
    allFlaggedRisks,
    type = FormRendererType.Platform,
  } = props;
  const clientForm = 'clientForm' in props ? props.clientForm : undefined;
  const form = 'form' in props ? props.form : props.clientForm.form;

  const { rootContext, rootFormSection } = useFormRendererInfo();

  const [mode, setMode] = useState<FormRendererMode>(initialMode);
  const [stepRendererModesInternal, setStepRendererModesInternal] = useState<Record<string, FormRendererMode> | undefined>(stepRendererModes);
  const [stepIndex, setStepIndex] = useState(0);
  const [validQuestionsInternal, setValidQuestionInternal] = useState<Record<string, boolean>>({});
  const [validQuestionsOverwrites, setValidQuestionOverwrites] = useState<Record<string, boolean>>({});
  const [toHighlight, setToHighlight] = useState<ActionHighlightInfo | null>(null);
  const [savingAnswersFor, setSavingAnswersFor] = useState<Record<string, boolean>>({});

  const [sectionActionOrder, setSectionActionOrder] = useState<Record<string, string[]>>({});
  const [actionLookup, setActionLookup] = useState<Record<string, FormAction>>({});
  const [questionAnswers, setQuestionAnswers] = useState<Record<string, any>>({});
  const [placeholders, setPlaceholders] = useState<Record<string, ActionPlaceholderData>>({});
  const [visibleActions, setVisibleActions] = useState<Record<string, boolean>>({});
  const [requiredActions, setRequiredActions] = useState<Record<string, boolean>>({});
  const { i18n: platformI18n } = useTranslation();
  const [i18nClone, setI18nClone] = useState(i18next.cloneInstance());
  const pendingCreatePlaceholders = useRef<Record<string, FormBuilderPlaceholder[]>>({});
  const pendingDeletePlaceholders = useRef<Record<string, string[]>>({});

  useEffect(() => {
    i18nClone.changeLanguage(languageOverride ?? platformI18n.language);
  }, [i18nClone, languageOverride, platformI18n.language]);

  useEffect(() => {
    setI18nClone(platformI18n.cloneInstance());
  }, [platformI18n]);

  const toasts = useToasts();

  if (![FormRendererMode.EditView, FormRendererMode.PreviewView].includes(mode)) {
    throw new Error('Unsupported mode for rendering');
  }

  const { publicFormId } = useParams<{ publicFormId: string }>();
  const isPublicForm = useMemo(() => !clientForm?.id && publicFormId, [clientForm?.id, publicFormId]);
  const isPreviewForm = useMemo(() => !clientForm?.id && !publicFormId, [clientForm?.id, publicFormId]);

  useEffect(() => {
    const initializeState = () => {
      const qs: Record<string, FormAction> = {};
      const qna: Record<string, any> = form.answers || {};
      const ps: Record<string, any> =
        clientForm?.placeholders ||
        (form.placeholders
          ? toRecord(
              form.placeholders.map(
                (x) =>
                  ({
                    placeholder: x.placeholder,
                    actionType: 'DateAction', // value doesn't matter, but need to provide it to satisfy type
                    translations: x.translations,
                    friendlyName: x.friendlyName,
                    dataFormat: x.dataFormat,
                    answers: [],
                    clientFormIds: [],
                    actionIds: [],
                  }) as ActionPlaceholderData & { placeholder: string },
              ),
              'placeholder',
            )
          : {}) ||
        {};
      const valids: Record<string, boolean> = {};
      const sectionQs: Record<string, string[]> = {};
      const visibleQs: Record<string, boolean> = {};
      const requiredQs: Record<string, boolean> = {};
      for (const step of form.sections) {
        sectionQs[step.id] = step.actions.map((x) => x.id);

        for (const action of step.actions) {
          qs[action.id] = action;
          visibleQs[action.id] = !!action.visible;
          requiredQs[action.id] = !!action.required;

          const isValid = isActionResponseValid(qna[action.id]);
          valids[action.id] = isValid;
        }
      }

      setQuestionAnswers(qna);
      setPlaceholders(ps);
      setValidQuestionInternal(valids);
      setSectionActionOrder(sectionQs);
      setActionLookup(qs);
      setVisibleActions(visibleQs);
      setRequiredActions(requiredQs);
    };

    initializeState();
  }, [clientForm?.placeholders, form.adHocAnswers, form.answers, form.placeholders, form.sections]);

  useEffect(() => {
    setStepRendererModesInternal(stepRendererModes);
  }, [stepRendererModes]);

  const { t } = useTranslation(['form', 'common']);

  useImperativeHandle(ref, () => ({
    changeStep: (index: number) => setStepIndex(index),
    setMode: (newMode: FormRendererMode) => setMode(newMode),
    highlight: (toHighlight: ActionHighlightInfo | null) => setToHighlight(toHighlight),
    pushState: (
      questions: Record<string, FormAction>,
      answers: Record<string, any>,
      placeholders: Record<string, ActionPlaceholderData>,
      visibleActions: Record<string, boolean>,
      requiredActions: Record<string, boolean>,
    ) => {
      setActionLookup(questions);
      setQuestionAnswers(answers);
      setPlaceholders(placeholders);
      setVisibleActions(visibleActions);
      setRequiredActions(requiredActions);
    },
    onStatusChanged: (triggerResponse: WorkflowTriggerResponse) => {
      const newQuestions = triggerResponse.addedSections
        .flatMap((section) => section.actions)
        .reduce(
          (prev, curr) => {
            prev[curr.id] = curr;
            return prev;
          },
          {} as Record<string, FormAction>,
        );
      const visibleQuestions = triggerResponse.addedSections
        .flatMap((section) => section.actions)
        .reduce(
          (prev, curr) => {
            prev[curr.id] = true;
            return prev;
          },
          {} as Record<string, boolean>,
        );

      setActionLookup((prev) => ({ ...prev, ...newQuestions }));
      const flattenedAddedAnswers = Object.keys(triggerResponse.addedAnswers).reduce((acc: Record<string, any>, key: string) => {
        acc[key] = triggerResponse.addedAnswers[key].data;
        return acc;
      }, {});
      setQuestionAnswers((prev) => ({ ...prev, ...flattenedAddedAnswers }));
      setVisibleActions((prev) => ({ ...prev, ...visibleQuestions }));
    },
  }));

  useEffect(() => {
    const handler = (evt: ActionHighlightInfo | null) => {
      setToHighlight(evt);
    };

    EventSystem.listen('highlight-question', handler);

    return () => {
      EventSystem.stopListening('highlight-question', handler);
    };
  }, []);

  const actionTypes = useMemo(() => {
    const types: Record<string, ActionTypeNames> = {};
    for (const step of form.sections) {
      for (const action of step.actions) {
        types[action.id] = action.type;
      }
    }

    return types;
  }, [form.sections]);

  const createForm = useCallback(
    async (data: ClientFormRequest) => {
      if (!clientForm?.id) {
        toasts.addToast({ title: 'Cannot create subform within FormBuilder', type: ToastType.WARNING, expiresInMs: 3000 });
        return null;
      }
      const res = await ClientFormService.allocateToClient(data);
      return res.data;
    },
    [clientForm?.id, toasts],
  );

  const createFormBulk = useCallback(
    async (data: ClientFormRequest[]) => {
      if (!clientForm?.id) {
        toasts.addToast({ title: 'Cannot create subform within FormBuilder', type: ToastType.WARNING, expiresInMs: 3000 });
        return [];
      }
      const res = await ClientFormService.allocateToClientBulk(data);
      return res.data;
    },
    [clientForm?.id, toasts],
  );

  const setQuestionValid = useCallback((id: string, valid?: boolean) => {
    setValidQuestionOverwrites((prev) => {
      if (valid !== undefined) {
        return { ...prev, [id]: valid };
      }
      const { [id]: _, ...newValid } = prev;
      return newValid;
    });
  }, []);

  const validQuestions = useMemo(() => {
    const result: Record<string, boolean> = {};
    for (const id of Object.keys(validQuestionsInternal)) {
      result[id] =
        validQuestionsOverwrites[id] !== undefined ? validQuestionsOverwrites[id] && validQuestionsInternal[id] : validQuestionsInternal[id];
    }

    return result;
  }, [validQuestionsInternal, validQuestionsOverwrites]);

  const visibleRequiredQuestionsCount = useMemo(
    () => Object.entries(visibleActions).filter(([key, value]) => value && actionLookup[key].required && !actionLookup[key].noninteractive).length,
    [actionLookup, visibleActions],
  );
  const validAnswersCount = useMemo(() => Object.values(validQuestions).filter((x) => x).length, [validQuestions]);

  useEffect(() => {
    const allQuestionsValid = visibleRequiredQuestionsCount <= validAnswersCount;
    nextTick(() => EventSystem.fireEvent('form-valid', { valid: allQuestionsValid, clientFormId: clientForm?.id }));
  }, [clientForm?.id, form.sections, isPublicForm, stepIndex, stepStatuses, validAnswersCount, visibleRequiredQuestionsCount]);

  const onAdHocAnswer = useCallback(
    (actionId: string, fieldId: string, payload: AdHocAnswerPayload) => {
      const { questionData, data: answer, numberingLevel, sortOrder } = payload;
      setSavingAnswersFor((prev) => ({ ...prev, [actionId]: true, [fieldId]: true }));

      if (isPreviewForm || featureToggles?.disableAutoAnswerSubmit) {
        setSavingAnswersFor((prev) => ({ ...prev, [actionId]: false, [fieldId]: false }));
        return Promise.resolve();
      }

      const newPlaceholders = pendingCreatePlaceholders.current[actionId] ?? [];
      pendingCreatePlaceholders.current[actionId] = [];
      const removedPlaceholders = pendingDeletePlaceholders.current[actionId] ?? [];
      pendingDeletePlaceholders.current[actionId] = [];

      const prevAnswer = form.adHocAnswers[actionId]?.find((x) => x.fieldId === fieldId);

      EventSystem.fireEvent('before-ad-hoc-form-answer', {
        answer,
        actionId,
        fieldId,
        actionType: actionTypes[actionId],
        clientFormId: clientForm?.id,
        adHocAnswer: {
          id: uuid(), // id doesn't matter here, so just generate one
          question: questionData ?? prevAnswer?.question ?? {},
          sortOrder: sortOrder ?? prevAnswer?.sortOrder ?? 0,
          actionId,
          data: answer === undefined ? prevAnswer?.data : answer, // allow answer to still be null & sent to BE
          fieldId,
          placeholders: {
            addedLookups: {},
            removedLookups: {},
          },
          numberingLevel: numberingLevel ?? prevAnswer?.numberingLevel ?? NumberingLevel.None,
        },
      });

      let promise: Promise<any>;
      if (isPublicForm) {
        promise = ClientPublicFormService.createAdHocAnswer(publicFormId as string, {
          question: questionData ?? prevAnswer?.question ?? {},
          sortOrder: sortOrder ?? prevAnswer?.sortOrder ?? 0,
          actionId,
          data: answer === undefined ? prevAnswer?.data : answer, // allow answer to still be null & sent to BE
          fieldId,
          numberingLevel: numberingLevel ?? prevAnswer?.numberingLevel ?? NumberingLevel.None,
        });
      } else {
        promise = FormResponseService.createAdHocAnswer({
          question: questionData ?? prevAnswer?.question ?? {},
          sortOrder: sortOrder ?? prevAnswer?.sortOrder ?? 0,
          clientFormId: clientForm?.id as string,
          actionId,
          data: answer === undefined ? prevAnswer?.data : answer, // allow answer to still be null & sent to BE
          addedPlaceholders: newPlaceholders,
          removedPlaceholders,
          fieldId,
          numberingLevel: numberingLevel ?? prevAnswer?.numberingLevel ?? NumberingLevel.None,
        });
      }

      return promise
        .then((res: ApiResponse<AdHocAnswerResponse>) => {
          const updatedAdHocAnswers = {
            ...form.adHocAnswers,
            [actionId]: [...(form.adHocAnswers[actionId] ?? []).filter((x) => x.fieldId !== res.data.fieldId), res.data].sort(
              (a, b) => a.sortOrder - b.sortOrder,
            ),
          };

          if (!isPublicForm) {
            nextTick(() => {
              EventSystem.fireEvent('form-ad-hoc-answer', { adHocAnswers: updatedAdHocAnswers, actionId: actionId, fieldId: res.data.fieldId });
            });
          }
        })
        .finally(() => {
          setSavingAnswersFor((prev) => ({ ...prev, [actionId]: false, [fieldId]: false }));
        });
    },
    [actionTypes, clientForm?.id, featureToggles?.disableAutoAnswerSubmit, form.adHocAnswers, isPreviewForm, isPublicForm, publicFormId],
  );

  const removeAdHocAnswer = useCallback(
    (actionId: string, fieldId: string) => {
      let promise: Promise<any>;
      if (isPublicForm) {
        promise = ClientPublicFormService.deleteAdHocAnswer(publicFormId as string, actionId, fieldId);
      } else {
        promise = FormResponseService.deleteAdHocAnswer(clientForm!.id, actionId, fieldId);
      }

      return promise.then(() => {
        EventSystem.fireEvent('form-remove-ad-hoc-answer', { [actionId]: form.adHocAnswers[actionId].filter((x) => x.fieldId !== fieldId) });
      });
    },
    [clientForm, form.adHocAnswers, isPublicForm, publicFormId],
  );

  const sortAdHocAnswers = useCallback(
    (actionId: string, fieldIds: string[]) => {
      EventSystem.fireEvent('form-ad-hoc-sorting', { actionId, fieldIds });
      setSavingAnswersFor((prev) => ({ ...prev, [actionId]: true }));

      let promise;
      if (isPublicForm) {
        promise = ClientPublicFormService.sortAdHocAnswers(publicFormId as string, actionId, fieldIds);
      } else {
        promise = FormResponseService.sortAdHocAnswers(clientForm?.id as string, actionId, fieldIds);
      }

      promise.finally(() => {
        setSavingAnswersFor((prev) => ({ ...prev, [actionId]: false }));
      });
    },
    [clientForm?.id, isPublicForm, publicFormId],
  );

  const onAnswer = useCallback(
    (questionId: string, answer: any) => {
      setSavingAnswersFor((prev) => ({ ...prev, [questionId]: true }));

      setQuestionAnswers((prev) => ({ ...prev, [questionId]: answer }));
      EventSystem.fireEvent('before-form-answer', {
        answer,
        actionId: questionId,
        actionType: actionTypes[questionId],
        clientFormId: clientForm?.id,
      });

      const isValid = Array.isArray(answer) ? answer.length > 0 : answer !== null && answer !== '';
      setValidQuestionInternal((prev) => ({ ...prev, [questionId]: isValid }));

      if (isPreviewForm || featureToggles?.disableAutoAnswerSubmit) {
        setSavingAnswersFor((prev) => ({ ...prev, [questionId]: false }));
        return Promise.resolve();
      }

      const newPlaceholders = pendingCreatePlaceholders.current[questionId] ?? [];
      pendingCreatePlaceholders.current[questionId] = [];
      const removedPlaceholders = pendingDeletePlaceholders.current[questionId] ?? [];
      pendingDeletePlaceholders.current[questionId] = [];

      let promise: Promise<any>;
      if (isPublicForm) {
        promise = ClientPublicFormService.answer(publicFormId as string, questionId, answer, form?.version, newPlaceholders, removedPlaceholders);
      } else {
        promise = FormResponseService.submitResponse(clientForm?.id as string, questionId, answer, newPlaceholders, removedPlaceholders);
      }
      return promise
        .then((res) => {
          const answerResponse = res.data as AnswerResponse;
          if (rootContext && clientForm?.id !== rootContext.clientForm?.id && !isPublicForm) {
            rootContext.refetchProgress();
          }

          if (!isPublicForm) {
            nextTick(() => {
              EventSystem.fireEvent('form-progress-updated', {
                clientFormId: clientForm?.id || '',
                clientFormProgress: answerResponse.clientFormProgress,
                sectionProgress: answerResponse.sectionProgress,
              });

              const sectionId = Object.entries(sectionActionOrder).find(([_, actions]) => actions.includes(questionId))?.[0] as string;
              EventSystem.fireEvent('form-answer', { ...answerResponse, type: actionTypes[questionId], sectionId });
            });
          }

          for (const questionId of answerResponse.removedQuestions) {
            const sectionId = Object.keys(sectionActionOrder).find((x) => sectionActionOrder[x].includes(questionId)) as string;
            EventSystem.fireEvent('question-visibility-changed', {
              actionId: questionId,
              sectionId,
              templateId: form.id,
              clientFormId: clientForm?.id,
              isNowVisible: false,
              action: {
                id: questionId,
                visible: false as any,
                type: 'Address', // not used at all, but required in type
              },
            });
          }

          for (const question of answerResponse.addedQuestions) {
            const sectionId = Object.keys(sectionActionOrder).find((x) => sectionActionOrder[x].includes(question.id)) as string;
            EventSystem.fireEvent('question-visibility-changed', {
              actionId: question.id,
              sectionId,
              templateId: form.id,
              clientFormId: clientForm?.id,
              isNowVisible: true,
              action: question,
            });
          }

          const removedQuestions = answerResponse.removedQuestions;
          const addedQuestions = answerResponse.addedQuestions.reduce(
            (dict, el) => {
              dict[el.id] = el;
              return dict;
            },
            {} as Record<string, FormAction>,
          );

          setVisibleActions((prev) => {
            const result = { ...prev };
            for (const q of answerResponse.addedQuestions) {
              result[q.id] = true;
            }
            for (const id of removedQuestions) {
              result[id] = false;
            }

            return result;
          });

          setActionLookup((prev) => ({ ...prev, ...addedQuestions }));

          const addedAnswers = answerResponse.addedAnswers?.reduce(
            (dict, el) => {
              dict[el.actionId] = el.data;
              return dict;
            },
            {} as Record<string, FormAnswerResponse>,
          );

          setQuestionAnswers((prev) => {
            const newLookup = { ...prev, ...addedAnswers };
            for (const id of removedQuestions) {
              delete newLookup[id];
            }

            return newLookup;
          });

          const validAnswers = answerResponse.addedAnswers?.reduce(
            (dict, el) => {
              dict[el.actionId] = isActionResponseValid(el.data);
              return dict;
            },
            {} as Record<string, boolean>,
          );

          setValidQuestionInternal((prev) => {
            const newLookup = { ...prev, ...validAnswers };
            for (const id of removedQuestions) {
              newLookup[id] = false;
            }

            return newLookup;
          });

          for (const questionId of Object.keys(answerResponse.questionRequiredChanged)) {
            const sectionId = Object.keys(sectionActionOrder).find((x) => sectionActionOrder[x].includes(questionId)) as string;
            EventSystem.fireEvent('question-requiredness-changed', {
              actionId: questionId,
              sectionId,
              templateId: form.id,
              clientFormId: clientForm?.id,
              isNowRequired: answerResponse.questionRequiredChanged[questionId],
            });
          }

          setRequiredActions((prev) => {
            const newLookup = { ...prev, ...answerResponse.questionRequiredChanged };
            for (const id of removedQuestions) {
              newLookup[id] = false;
            }

            return newLookup;
          });
        })
        .finally(() => {
          setSavingAnswersFor((prev) => ({ ...prev, [questionId]: false }));
        });
    },
    [
      actionTypes,
      clientForm?.id,
      featureToggles?.disableAutoAnswerSubmit,
      form.id,
      form?.version,
      isPreviewForm,
      isPublicForm,
      publicFormId,
      rootContext,
      sectionActionOrder,
    ],
  );

  const visibleActionsList = useMemo(
    () =>
      Object.entries(visibleActions)
        .filter(([_, visible]) => visible)
        .map(([id, _]) => id),
    [visibleActions],
  );

  const visibleSections = useMemo(() => (clientForm?.id ? form.sections.filter((x) => !!x.visible) : form.sections), [clientForm?.id, form.sections]);

  const isEmpty = useMemo(() => {
    return visibleSections.length === 0 || visibleSections.every((x) => x.actions.length === 0);
  }, [visibleSections]);

  const stepsToRender = useMemo(() => {
    const updateActionsNumbering = (actions: FormAction[], stepNumber: string, initialNumber: number): number => {
      let currentNumber = initialNumber;

      actions.forEach((action) => {
        if (action.data?.useDocumentNumbering || action.type === 'ChildFormListAction' || action.type === 'AdHocFieldsAction') {
          if (action.type === 'AdHocFieldsAction') {
            // We need to find the adhoc fields for the action and update the number in the form.adhocAnswers
            const adHocFields = form.adHocAnswers ? form.adHocAnswers[action.id] ?? [] : [];
            let belowNumber = 0;
            let isPrevBelow = false;

            adHocFields.forEach((field) => {
              if (action.data?.useDocumentNumbering || field.numberingLevel === NumberingLevel.Current) {
                currentNumber += 1;
                field.number = `${stepNumber}${currentNumber}.`;
                field.nextNumbers = {
                  current: `${stepNumber}${currentNumber}.`,
                  below: `${stepNumber}${currentNumber - 1}.${isPrevBelow ? belowNumber + 1 : 1}.`,
                };
                isPrevBelow = false;
                belowNumber = 0;
              } else if (field.numberingLevel === NumberingLevel.Below) {
                belowNumber += 1;
                field.nextNumbers = {
                  current: `${stepNumber}${currentNumber + 1}.`,
                  below: `${stepNumber}${currentNumber}.${isPrevBelow ? belowNumber + 1 : 1}.`,
                };
                field.number = `${stepNumber}${currentNumber}.${belowNumber}.`;
                isPrevBelow = true;
              } else {
                field.nextNumbers = {
                  current: `${stepNumber}${currentNumber + 1}.`,
                  below: `${stepNumber}${currentNumber}.${isPrevBelow ? belowNumber + 1 : 1}.`,
                };
                isPrevBelow = false;
              }
            });
          } else {
            currentNumber += 1;
            action.number = `${stepNumber}${currentNumber}.`;
          }
        }
      });

      return currentNumber;
    };

    const visibleSteps = visibleSections.filter((step) => {
      // hide when hiddenFromExport and all questions are invisible and hidden from preview
      return (
        (mode === FormRendererMode.EditView ? true : step.isHiddenFromExport !== true) &&
        !step?.actions.every((x) => !x.visible || (x.previewHidden && mode === FormRendererMode.PreviewView))
      );
    });

    visibleSteps.forEach((step, i) => {
      const currentStepNumber = number ? `${number}${i + 1}.` : `${i + 1}.`;
      step.number = currentStepNumber;
      updateActionsNumbering(step?.actions, currentStepNumber, 0);
    });

    // visibleSections are also modified in the above foreach
    return renderAllSteps ? visibleSteps : [visibleSections[stepIndex]].filter(Boolean);
  }, [form.adHocAnswers, mode, number, renderAllSteps, stepIndex, visibleSections]);

  const stepStatusLookup = useMemo(() => {
    if (!stepStatuses) {
      return {};
    }

    const steps: Record<string, ClientFormSectionStatus> = {};
    for (const step of stepStatuses) {
      steps[step.templateFormSectionId] = step.status;
    }
    return steps;
  }, [stepStatuses]);

  const refetchProgress = useCallback(() => {
    if (!clientForm?.id) {
      return;
    }

    ClientFormService.getFormProgress(clientForm?.id).then((res) => {
      EventSystem.fireEvent('form-progress-updated', {
        clientFormId: clientForm?.id,
        clientFormProgress: {
          totalAnswers: res.data.totalRequiredAnswers,
          totalRequired: res.data.totalRequiredActions,
        },
        sectionProgress: res.data.sectionProgress,
      });
    });
  }, [clientForm?.id]);

  const changeMode = useCallback((newMode: FormRendererMode, stepId?: string) => {
    if (!stepId) {
      setMode(newMode);
    } else {
      setStepRendererModesInternal((prev) => ({ ...prev, [stepId]: newMode }));
    }
  }, []);

  useEffect(() => {
    const notifyValidSections = () => {
      const results: Record<string, boolean> = {};
      for (const section of form.sections) {
        const valid = section.actions
          .filter((x) => actionLookup[x.id]?.required)
          .map((x) => validQuestions[x.id])
          .every((x) => x);
        results[section.id] = valid;
      }
      EventSystem.fireEvent('form-section-valid', { clientFormId: clientForm?.id, sectionValid: results });
    };

    notifyValidSections();
  }, [actionLookup, clientForm?.id, form.sections, validQuestions]);

  const createPlaceholder = useCallback((placeholder: FormBuilderPlaceholder) => {
    pendingCreatePlaceholders.current[placeholder.targetId] ??= [];
    pendingCreatePlaceholders.current[placeholder.targetId].push(placeholder);
  }, []);

  const deletePlaceholder = useCallback((actionId: string, placeholder: string) => {
    pendingDeletePlaceholders.current[actionId] ??= [];
    pendingDeletePlaceholders.current[actionId].push(placeholder);
  }, []);

  const contextValues: FormRendererContextType = useMemo(() => {
    const value = {
      clientFormId: clientForm?.id,
      clientForm,
      mode,
      changeMode,
      readOnly: !canEdit,
      config: form,
      containingTemplateId: form.id,
      containingModuleId: moduleId,
      containingModuleSectionId: moduleSectionId,
      isDisplayedInline: !!rootContext,
      createForm,
      createFormBulk,
      validQuestions,
      setQuestionValid,
      onAnswer,
      renderMinimal: false,
      rootFormSection: rootFormSection ?? form.sections[stepIndex],
      actionLookup,
      requiredActions,
      visibleActions,
      visibleActionsList,
      questionAnswers,
      placeholders,
      actionRisks,
      allFlaggedRisks,
      featureToggles,
      toHighlight: rootContext?.toHighlight || toHighlight,
      refetchProgress,
      language: languageOverride,
      isCommentsVisible,
      answersBusySaving: savingAnswersFor,
      createPlaceholder,
      deletePlaceholder,
      adHocAnswers: form.adHocAnswers ?? {},
      onAdHocAnswer,
      removeAdHocAnswer,
      sortAdHocAnswers,
      rootContext: null as any,
      type: type,
    };

    value.rootContext = rootContext || value;

    return value;
  }, [
    clientForm,
    mode,
    changeMode,
    canEdit,
    form,
    moduleId,
    moduleSectionId,
    rootContext,
    createForm,
    createFormBulk,
    validQuestions,
    setQuestionValid,
    onAnswer,
    rootFormSection,
    stepIndex,
    actionLookup,
    requiredActions,
    visibleActions,
    visibleActionsList,
    questionAnswers,
    placeholders,
    actionRisks,
    allFlaggedRisks,
    featureToggles,
    toHighlight,
    refetchProgress,
    languageOverride,
    isCommentsVisible,
    savingAnswersFor,
    createPlaceholder,
    deletePlaceholder,
    onAdHocAnswer,
    removeAdHocAnswer,
    sortAdHocAnswers,
    type,
  ]);

  if (isEmpty) {
    return (
      <div data-cy="empty-form-warn" className="flex h-full flex-col items-center justify-center">
        <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
        <Heading size={HeadingSize.H3}>{t('form:empty.heading', { type: t(FormTypeKeys[form.type]) })}</Heading>
        <div>{t('form:empty.subheading')}</div>
      </div>
    );
  }

  return (
    <FormRendererInfoContext.Provider value={contextValues}>
      <Suspense fallback={<PageLoader loading isSuspense />}>
        <I18nextProvider i18n={i18nClone}>
          {visibleSections.length > 0 &&
            stepsToRender.map((step) => (
              <FormSectionRendererV2
                key={step.id}
                step={step}
                mode={stepRendererModesInternal?.[step.id] ?? mode}
                actionFilter={actionFilter}
                stepStatus={stepStatusLookup[step.id]}
                actionOrder={sectionActionOrder[step.id] ?? EMPTY_ARRAY}
              />
            ))}
        </I18nextProvider>
      </Suspense>
    </FormRendererInfoContext.Provider>
  );
};

export default forwardRef(FormRendererV2);
