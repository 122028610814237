/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, Fragment } from 'react';
import { FormBuilderPlaceholder } from '../../components/form-builder/FormBuilderTypes';
import { ActionPlaceholderData } from '../../models/Form';
import { toRecord } from '../ListUtils';
import { interpolate } from './InterpolationUtils';
import { Translation } from 'react-i18next';
import PlaceholderMissing from '../../components/form/PlaceholderMissing';
import PlaceholderFiendlyName from '../../components/form/PlaceholderFiendlyName';
import ActionTypes from '../../components/form/ActionTypes';

export const friendlyNameInterpolationReplacer = (_: string, placeholderData: ActionPlaceholderData) => {
  if (!placeholderData) {
    return <Translation>{(t) => '{' + t('common:placeholder-missing.text') + '}'}</Translation>;
  }

  return '{' + placeholderData.friendlyName + '}';
};

export const interpolateActionData = (
  text: string,
  placeholderData: Record<string, ActionPlaceholderData> | FormBuilderPlaceholder[],
  interpolationReplacer: (
    placeholder: string,
    placeholderData: any,
    key: number,
    allPlaceholderData: Record<string, ActionPlaceholderData>,
  ) => ReactElement | string = actionDataInterpolationReplacer,
) => {
  const isFormBuilderPlaceholder = (value: Record<string, ActionPlaceholderData> | FormBuilderPlaceholder[]): value is FormBuilderPlaceholder[] => {
    return Array.isArray(value);
  };

  if (isFormBuilderPlaceholder(placeholderData)) {
    placeholderData = toRecord(
      placeholderData.map((x) => {
        return {
          placeholder: x.placeholder,
          actionType: 'DateAction', // value doesn't matter, but need to provide it to satisfy type
          translations: x.translations,
          friendlyName: x.friendlyName,
          dataFormat: x.dataFormat,
          answers: [],
          clientFormIds: [],
          actionIds: [],
        } as ActionPlaceholderData & { placeholder: string };
      }),
      'placeholder',
    );
  }

  const result = interpolate(text, placeholderData, interpolationReplacer, /\${{([\S]+?)}}/g);

  if (interpolationReplacer === friendlyNameInterpolationReplacer) {
    return result.join(' ');
  }

  return result.map((x, i) => <Fragment key={i}>{x}</Fragment>);
};

export const actionDataInterpolationReplacer = (
  _: string,
  placeholderData: ActionPlaceholderData,
  key: number,
  otherPlaceholders: Record<string, ActionPlaceholderData>,
) => {
  if (!placeholderData) {
    return <PlaceholderMissing key={key} />;
  }

  const actionType = ActionTypes[placeholderData.actionType];

  if (
    actionType &&
    !!actionType.getPlaceholderData &&
    placeholderData.answers.filter((x) => !!x).length > 0 &&
    // empty picklist answers are arrays with an empty array in it
    !(placeholderData.answers.length === 1 && placeholderData.answers[0].length === 0)
  ) {
    placeholderData.answers = placeholderData.answers.map((answer) => {
      if (!answer) {
        answer = <PlaceholderFiendlyName placeholderData={placeholderData} key={key} />;
      }
      return answer;
    });
    return actionType.getPlaceholderData(placeholderData, otherPlaceholders);
  }

  return <PlaceholderFiendlyName placeholderData={placeholderData} key={key} />;
};
