import i18n from '../i18n';
import { SupportedLanguage } from '../types/Languages';
import { Translations } from '../models/Translation';
import { DocumentListItem, DocumentResponse } from '../models/Document';
import LanguageUtils from './LanguageUtils';
import StringUtils from './StringUtils';

type FormLite = {
  subtitle?: string;
  form: {
    translations: Translations;
  };
};

export default class FormUtils {
  public static getFormTitle(formLite: FormLite, lang: SupportedLanguage | null = null): string {
    const language: SupportedLanguage = lang || i18n.language;
    const translations = formLite.form?.translations ? formLite.form.translations[language] || {} : {};
    return formLite.subtitle || translations.title || '';
  }

  public static getDocumentTitle(document: DocumentListItem | DocumentResponse) {
    return `${StringUtils.makePrefixWithNumber(document.prefix, document.documentNumber, document.templateModuleTranslations)}-${
      document.subtitle || LanguageUtils.getTranslation('title', 'translations' in document ? document.translations : document.form.translations)
    }`;
  }

  public static getFormTemplateTitle(form: FormLite, lang: SupportedLanguage | null = null): string {
    const language: SupportedLanguage = lang || i18n.language;
    const translations = form.form?.translations ? form.form.translations[language] || {} : {};
    return translations.title || '';
  }

  public static formHostName = (formClient: { clientId: string; clientName: string }, clientId: string, format?: string): string => {
    if (formClient.clientId !== clientId) {
      return format ? format.replace('{client}', formClient.clientName) : `${formClient.clientName}`;
    }
    return '';
  };
}
