import { FC, useEffect, useMemo, useState } from 'react';
import { Option } from '../Option';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import { Action, FormBuilderForm } from './FormBuilderTypes';
import ActionTypes, { EditorConditional } from '../form/ActionTypes';
import { Input } from '../shared/form-control/Input';
import PickListService from '../../services/PickListService';
import ObjectUtils from '../../utils/ObjectUtils';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import LanguageUtils from '../../utils/LanguageUtils';
import Tooltip from '../shared/Tooltip';
import { TFunction } from 'i18next';
import { Namespace } from 'i18next';

export const yesNoComparisonOptions = (t: TFunction<Namespace>) => {
  return [
    { id: 'yes', text: t('activity-type:yes-no.yes'), value: 'yes' },
    { id: 'no', text: t('activity-type:yes-no.no'), value: 'no' },
    { id: 'na', text: t('activity-type:yes-no.not-applicable'), value: 'na' },
  ];
};

type ConditionGeneratorProps = {
  action?: Action;
  form: FormBuilderForm;
  condition?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateCondition: (condition: string) => void;
  disabled?: boolean;
  lockToAction?: boolean;
  invalidStates?: Record<string, string[]>;
  lockToSectionId?: string;
};

const ConditionGenerator: FC<ConditionGeneratorProps> = (props) => {
  const { action, form, condition, updateCondition, disabled, lockToAction, invalidStates, lockToSectionId } = props;

  const [comparisonValueType, setComparisonValueType] = useState<string | undefined>('');
  const [comparisonValueOptions, setComparisonValueOptions] = useState<Option<string, string>[]>([]);

  const [comparisonAction, setComparisonAction] = useState<Action | null>(null);
  const [comparisonActionId, setComparisonActionId] = useState(lockToAction ? action?.id || '' : '');
  const [comparisonActionName, setComparisonActionName] = useState('');
  const [comparisonType, setComparisonType] = useState('');
  const [comparisonValue, setComparisonValue] = useState('');
  const [conditional, setConditional] = useState<EditorConditional | undefined>();

  const currentClient = useRecoilValue(currentClientAtom);
  const { t } = useTranslation(['form-builder', 'activity-type']);

  if (lockToAction && !action) {
    throw new Error('no action provided for lockToAction to work!');
  }

  const actionOptions: Option<string, string>[] = useMemo(() => {
    const result = [{ id: '', text: t('form-builder:action-properties.common.none-option'), value: '' }];
    const canSelectAction = (action: Action) => {
      const actionType = ActionTypes[action.type];
      if ((actionType?.editorConditionals?.length || 0) === 0) {
        return false;
      }
      const sourceType = actionType?.conditionalsValueSource?.(action);
      if ((sourceType?.indexOf('\0') ?? 0) !== -1) {
        return false;
      }
      return true;
    };

    for (const section of form.sections) {
      const canSelectFromSection = !(section.isDraft || (lockToSectionId && section.id !== lockToSectionId));

      if (canSelectFromSection && section.actions.some((x) => canSelectAction(x))) {
        result.push({
          id: '',
          text: LanguageUtils.getTranslation('title', section.translations || {}),
          value: '',
          disabled: true,
          hasDivider: true,
        } as Option<string, string>);
      } else {
        continue;
      }

      for (const action of section.actions) {
        if (!canSelectAction(action)) {
          continue;
        }

        const actionText = `${action.number} ${
          LanguageUtils.getTranslation('question', action.translations || {}) ||
          LanguageUtils.getTranslation('title', action.translations || {}) ||
          `${t(`form-builder:action-types.${action.type}`)}`
        } ${invalidStates && invalidStates[action.id]?.length ? '⚠' : ''}`;
        result.push({ id: action.id, text: actionText || t('form-builder:error-states.unknown-action'), value: action.id });
      }
    }

    return result;
  }, [form.sections, invalidStates, lockToSectionId, t]);

  useEffect(() => {
    ObjectUtils.DeepClone(form.sections).forEach((section) => {
      section.actions.forEach((sectionAction) => {
        if (comparisonActionId === sectionAction.id) {
          setComparisonActionName(actionOptions.find((x) => x.id === sectionAction.id)?.text || '');
          setComparisonAction(sectionAction);
        }

        if (comparisonActionId === '') {
          setComparisonAction(null);
        }
      });
    });
  }, [action, actionOptions, comparisonActionId, form.sections, invalidStates, lockToSectionId, t]);

  const resetComparison = () => {
    setComparisonActionId('');
    setComparisonType('');
    setComparisonValue('');
    setComparisonActionName('');
  };

  useEffect(() => {
    if (condition?.length === 0 && !lockToAction) {
      resetComparison();
    }

    const splitCondition = condition?.split(' ');
    if (splitCondition?.length !== 3 && splitCondition?.length !== 4) {
      return;
    }

    const splitAction = splitCondition[0].split('$');
    setComparisonActionId(splitAction[0]);
    setComparisonType(splitCondition[1]);

    if (comparisonAction?.type) {
      const conditional = ActionTypes[comparisonAction?.type]?.editorConditionals?.find((conditional) => conditional.operation === splitCondition[1]);
      setConditional(conditional);
    }

    let value = splitCondition[2];
    if (value.startsWith("'") && value.endsWith("'")) {
      value = value.substr(1, value.length - 2);
    }
    setComparisonValue(value);
  }, [comparisonAction?.type, condition, lockToAction]);

  useEffect(() => {
    if (!comparisonAction?.type) {
      return;
    }

    const actionType = ActionTypes[comparisonAction.type];
    let type = actionType?.conditionalsValueSource?.(comparisonAction);

    const valueSource = actionType?.editorConditionals?.find((conditional) => conditional.operation === comparisonType)?.valueSource;

    if (valueSource) {
      type = valueSource(comparisonAction);
    }
    if (comparisonValueType === type) {
      return;
    }
    setComparisonValueType(type);
    setComparisonValueOptions([]);
    if (type === undefined || ['text', 'yesno', 'tickbox'].indexOf(type) > -1 || type.indexOf('\0') !== -1) {
      return;
    }

    PickListService.getPickList(type).then((res) => {
      setComparisonValueOptions(
        res.data.items.map((item) => ({ id: item.id, value: item.id, text: LanguageUtils.getTranslation('name', item.translations) })),
      );
    });
  }, [comparisonAction, comparisonType, comparisonValueType, currentClient?.id]);

  const onComparisonActionChanged = (option: Option<string, string | number>) => {
    setComparisonActionId(option.id);
    setComparisonType('');
    setComparisonValue('');
    setComparisonActionName('');

    updateCondition(`${option.id || ''}`);
  };

  const onComparisonTypeChanged = (option: Option<string, string | number>) => {
    setComparisonType(option.id);

    const conditional =
      comparisonAction?.type && ActionTypes[comparisonAction?.type]?.editorConditionals?.find((conditional) => conditional.operation === option.id);
    const actionPath = conditional?.path;

    if (['EMPTY', '!EMPTY'].includes(conditional?.operation || '')) {
      setComparisonValue('na');
      updateCondition(`${comparisonActionId || ''}${actionPath || ''} ${option.id || ''} 'na'`);
    } else {
      setComparisonValue('');
      updateCondition(`${comparisonActionId || ''}${actionPath || ''} ${option.id || ''}`);
    }

    setConditional(conditional);
  };

  const onComparisonValueChanged = (option: Option<string, string | number> | string) => {
    const val = typeof option === 'string' ? option : option.id;
    setComparisonValue(val);

    if (comparisonAction?.type) {
      const actionPath = ActionTypes[comparisonAction?.type]?.editorConditionals?.find(
        (conditional) => conditional.operation === comparisonType,
      )?.path;

      updateCondition(`${comparisonActionId || ''}${actionPath || ''} ${comparisonType || ''} '${val || ''}'`);
    }
  };

  const comparisonTypes: Option<string, string>[] = useMemo(
    () =>
      (comparisonAction?.type &&
        ActionTypes[comparisonAction?.type || '']?.editorConditionals?.map((conditional) => ({
          id: conditional.operation,
          text: t(`form-builder:${conditional.friendlyName}`),
          value: conditional.path,
        }))) ||
      [],
    [comparisonAction?.type, t],
  );

  const comparisonTypeText = useMemo(() => comparisonTypes.find((x) => x.id === comparisonType)?.text, [comparisonType, comparisonTypes]);
  const comparisonValueText = useMemo(
    () => comparisonValueOptions.find((x) => x.id === comparisonValue)?.text,
    [comparisonValue, comparisonValueOptions],
  );

  const ListItemRenderer: FC<Option<string, string>> = (props) => {
    const { text } = props;

    return (
      <Tooltip text={text} truncatedTextMode>
        {(tooltip) => (
          <div {...tooltip} className="min-w-full max-w-md truncate">
            {text}
          </div>
        )}
      </Tooltip>
    );
  };

  return (
    <div data-cy="conditions-generator">
      <div className="flex items-center gap-2">
        {lockToAction && (
          <div
            className="border-primary-1 w-1/3 cursor-not-allowed select-none rounded-lg border-2 px-3 py-2 font-medium text-black opacity-50"
            data-cy="action-name-locked"
          >
            {comparisonActionName}
          </div>
        )}
        {!lockToAction && (
          <DropdownSelect
            data-cy="action"
            wrapperClassName="w-1/3"
            options={actionOptions}
            customListRenderer={ListItemRenderer}
            placeholder={t('form-builder:action-properties.common.conditionals.action')}
            onChange={onComparisonActionChanged}
            value={{ id: comparisonActionId, text: comparisonActionName || '', value: comparisonActionId }}
            disabled={disabled}
          />
        )}
        <DropdownSelect
          data-cy="comparison"
          wrapperClassName="w-1/3"
          disabled={disabled || !comparisonActionId}
          options={comparisonTypes}
          placeholder={t('form-builder:action-properties.common.conditionals.comparison')}
          onChange={onComparisonTypeChanged}
          value={{ id: comparisonType, text: comparisonTypeText || '', value: comparisonType }}
        />
        {!['EMPTY', '!EMPTY'].includes(conditional?.operation || '') && (
          <>
            {comparisonValueType === 'text' ? (
              <div className="w-1/3">
                <Input
                  data-cy="value"
                  disabled={disabled || !comparisonType}
                  placeholder={t('form-builder:action-properties.common.conditionals.value')}
                  value={comparisonValue}
                  onChange={(e) => onComparisonValueChanged(e.target.value)}
                />
              </div>
            ) : comparisonValueType === 'yesno' ? (
              <DropdownSelect
                data-cy="value"
                disabled={disabled || !comparisonType}
                wrapperClassName="w-1/3"
                options={yesNoComparisonOptions(t).filter((x) => (comparisonAction?.data?.enableNA ? true : x.id !== 'na'))}
                placeholder="Value"
                onChange={onComparisonValueChanged}
                value={{
                  id: comparisonValue,
                  text:
                    comparisonValue === 'na'
                      ? t('activity-type:yes-no.not-applicable')
                      : comparisonValue === 'yes'
                        ? t('activity-type:yes-no.yes')
                        : comparisonValue === 'no'
                          ? t('activity-type:yes-no.no')
                          : '',
                  value: comparisonValue,
                }}
              />
            ) : comparisonValueType === 'tickbox' ? (
              <DropdownSelect
                data-cy="value"
                disabled={disabled || !comparisonType}
                wrapperClassName="w-1/3"
                options={[
                  { id: 'true', text: t('activity-type:tickbox.checked'), value: 'checked' },
                  { id: 'null', text: t('activity-type:tickbox.unchecked'), value: 'unchecked' },
                ]}
                placeholder="Value"
                onChange={onComparisonValueChanged}
                value={{
                  id: comparisonValue,
                  text:
                    comparisonValue === 'true'
                      ? t('activity-type:tickbox.checked')
                      : comparisonValue === 'null'
                        ? t('activity-type:tickbox.unchecked')
                        : '',
                  value: comparisonValue,
                }}
              />
            ) : (
              <DropdownSelect
                data-cy="value"
                disabled={disabled || !comparisonType}
                wrapperClassName="w-1/3"
                options={comparisonValueOptions}
                placeholder={t('form-builder:action-properties.common.conditionals.value')}
                onChange={onComparisonValueChanged}
                value={{ id: comparisonValue, text: comparisonValueText || '', value: comparisonValue }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ConditionGenerator;
