import { FormBuilderPlaceholder } from '../components/form-builder/FormBuilderTypes';
import { ApiResponse } from '../models/ApiResponse';
import { AdHocAnswerResponse, AdHocFormAnswerRequest, AnswerResponse, BulkFormAnswerPayload } from '../models/Form';
import { GetResponsesFilter, FormResponse } from '../models/FormResponses';
import BaseService from './BaseService';

export default class FormResponseService extends BaseService {
  public static submitResponse(
    clientFormId: string,
    actionId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    addedPlaceholders: FormBuilderPlaceholder[] | undefined,
    removedPlaceholders: string[] | undefined,
  ): Promise<ApiResponse<AnswerResponse>> {
    return this.post('/v1/responses', { clientFormId, actionId, data, addedPlaceholders, removedPlaceholders });
  }

  public static submitResponseBulk(payload: BulkFormAnswerPayload) {
    return this.post('/v1/responses/bulk', payload);
  }

  public static getResponses(filter: GetResponsesFilter): Promise<ApiResponse<FormResponse[]>> {
    return this.get('/v1/responses', { params: filter });
  }

  public static createAdHocAnswer(payload: AdHocFormAnswerRequest): Promise<ApiResponse<AdHocAnswerResponse>> {
    return this.post('/v1/responses/ad-hoc', payload);
  }

  public static deleteAdHocAnswer(clientFormId: string, actionId: string, fieldId: string): Promise<ApiResponse<void>> {
    return this.delete('/v1/responses/ad-hoc', { params: { clientFormId, actionId, fieldId } });
  }

  public static sortAdHocAnswers(clientFormId: string, actionId: string, fieldIds: string[]): Promise<ApiResponse<boolean>> {
    return this.post('/v1/responses/ad-hoc/sort', { clientFormId, actionId, fieldIds });
  }
}
