/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionPreviewBaseProps } from './action-types/ActionBaseProps';
import { lazy, FC, useState, useMemo, useEffect } from 'react';
import { FormType } from '../../models/FormTypes';
import { FCWithChildren } from '../../types/FCWithChildren';
import { Action, EditorProps, FormBuilderForm, Section } from '../form-builder/FormBuilderTypes';
import { SupportedLanguage } from '../../types/Languages';
import ActionBaseProps from './action-types/ActionBaseProps';
import { ActionPlaceholderData } from '../../models/Form';
import DefaultPlaceholderFormat from './DefaultPlaceholderFormat';
import MailtoPlaceholderLink from './MailtoPlaceholderLink';
import { PlaceholderFormat } from '../../models/Form';
import DateUtils from '../../utils/DateUtils';
import { v4 as uuid } from 'uuid';
import CurrencyPlaceholderWrapper from './CurrencyPlaceholderWrapper';
import PhoneNumberPlaceholderWrapper from './PhoneNumberPlaceholderWrapper';
import FilePlaceholderLink from './FilePlaceholderLink';
import { TFunction } from 'i18next';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormActionWrapperProps } from '../../contexts/FormRendererDesignContextTypes';

const VALIDATION_FAIL_TITLE_REQUIRED = 'error-states.question-title-required';
const VALIDATION_FAIL_DESCRIPTION_REQUIRED = 'error-states.question-description-required';
const VALIDATION_FAIL_HELP_REQUIRED = 'error-states.question-help-required';
const VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE = 'error-states.question-visibility-condition-incomplete';
const VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE = 'error-states.question-required-condition-incomplete';
const VALIDATION_FAIL_RISK_CONDITION_INCOMPLETE = 'error-states.question-risk-condition-incomplete';
const VALIDATION_FAIL_PICKLIST_SOURCE_INVALID = 'error-states.question-picklist-source-invalid';
const VALIDATION_FAIL_TEMPLATE_SOURCE_INVALID = 'error-states.question-template-source-invalid';
const VALIDATION_FAIL_TEMPLATE_SOURCE_UNASSOCIATED = 'error-states.question-template-unassociated';
const VALIDATION_FAIL_PRESELEDTED_FORM_TITLE_INVALID = 'error-states.question-preselected-form-title-invalid';
const VALIDATION_FAIL_BUTTON_TEXT_INVALID = 'error-states.question-button-text-invalid';
const VALIDATION_FAIL_DYNAMIC_DATA_REQUIRES_TEMPLATE = 'error-states.dynamic-data-requires-template';

const conditionalKeys = {
  equals: 'conditionals.equals',
  notEquals: 'conditionals.not-equals',
  isEmpty: 'conditionals.is-empty',
  isNotEmpty: 'conditionals.is-not-empty',
  contains: 'conditionals.contains',
  notContains: 'conditionals.not-contains',
  greaterThan: 'conditionals.greater-than',
  greaterThanOrEquals: 'conditionals.greater-than-or-equals',
  lessThan: 'conditionals.less-than',
  lessThanOrEquals: 'conditionals.less-than-or-equals',
  charCountEquals: 'conditionals.char-count-equals',
  charCountNotEquals: 'conditionals.char-count-not-equals',
  charCountGreaterThan: 'conditionals.char-count-greater-than',
  charCountGreaterThanOrEquals: 'conditionals.char-count-greater-than-or-equals',
  charCountLessThan: 'conditionals.char-count-less-than',
  charCountLessThanOrEquals: 'conditionals.char-count-less-than-or-equals',
  fileCountEquals: 'conditionals.file-count-equals',
  fileCountNotEquals: 'conditionals.file-count-not-equals',
  fileCountGreaterThan: 'conditionals.file-count-greater-than',
  fileCountGreaterThanOrEquals: 'conditionals.file-count-greater-than-or-equals',
  fileCountLessThan: 'conditionals.file-count-less-than',
  fileCountLessThanOrEquals: 'conditionals.file-count-less-than-or-equals',
  tagsCountEquals: 'conditionals.tags-count-equals',
  tagsCountNotEquals: 'conditionals.tags-count-not-equals',
  tagsCountGreaterThan: 'conditionals.tags-count-greater-than',
  tagsCountGreaterThanOrEquals: 'conditionals.tags-count-greater-than-or-equals',
  tagsCountLessThan: 'conditionals.tags-count-less-than',
  tagsCountLessThanOrEquals: 'conditionals.tags-count-less-than-or-equals',
  subformCountEquals: 'conditionals.subform-count-equals',
  subformCountNotEquals: 'conditionals.subform-count-not-equals',
  subformCountGreaterThan: 'conditionals.subform-count-greater-than',
  subformCountGreaterThanOrEquals: 'conditionals.subform-count-greater-than-or-equals',
  subformCountLessThan: 'conditionals.subform-count-less-than',
  subformCountLessThanOrEquals: 'conditionals.subform-count-less-than-or-equals',
  formCountEquals: 'conditionals.form-count-equals',
  formCountNotEquals: 'conditionals.form-count-not-equals',
  formCountGreaterThan: 'conditionals.form-count-greater-than',
  formCountGreaterThanOrEquals: 'conditionals.form-count-greater-than-or-equals',
  formCountLessThan: 'conditionals.form-count-less-than',
  formCountLessThanOrEquals: 'conditionals.form-count-less-than-or-equals',
  documentCountEquals: 'conditionals.document-count-equals',
  documentCountNotEquals: 'conditionals.document-count-not-equals',
  documentCountGreaterThan: 'conditionals.document-count-greater-than',
  documentCountGreaterThanOrEquals: 'conditionals.document-count-greater-than-or-equals',
  documentCountLessThan: 'conditionals.document-count-less-than',
  documentCountLessThanOrEquals: 'conditionals.document-count-less-than-or-equals',
} as const;

export type EditorConditional = {
  friendlyName: (typeof conditionalKeys)[keyof typeof conditionalKeys];
  path: string;
  operation: string;
  valueSource?: (action: any) => string;
  rightValue?: string;
};

export type ActionTypesInfo = {
  editAction: FC<ActionBaseProps<any, any>>;
  builderEditor: FC<EditorProps>;
  resourceEditor?: FC<EditorProps>;
  previewAction: FCWithChildren<ActionBaseProps<any, any> | ActionPreviewBaseProps<any, any>>;
  pdfPreview?: FC<any>;
  hidden?: boolean;
  resourceFriendly?: boolean;
  resourceAlignment?: 'left' | 'right';
  dynamicData?: {
    enabled: boolean;
    canHaveSecondaryData?: boolean;
    hasMultipleData?: boolean;
    formatOptions?: readonly PlaceholderFormat[];
  };
  editorConditionals?: readonly EditorConditional[];
  customActionWrapper?: FC<FormActionWrapperProps> | string;
  conditionalsValueSource?: (action: any) => string;
  actionTitle: (action: any, languageCode: SupportedLanguage) => string;
  validate?: (
    action: Action,
    t: TFunction<'form-builder'>,
    {
      validateConditional,
      validateRisk,
      validateRequired,
      validateAsset,
      disallowInPublicSection,
      conditionallyApplyRule,
    }: {
      validateConditional: (condition: string | undefined, message: string) => null | [string, boolean];
      validateRisk: (riskEnabled: boolean | undefined, conditions: string[] | undefined, message: string) => Array<null | [string, boolean]>;
      validateRequired: (value: string | undefined, message: string) => null | [string, boolean];
      validateAsset: (assetId: string | undefined, message: string) => null | [string, boolean];
      disallowInPublicSection: (actionId: string | undefined) => null | [string, boolean];
      conditionallyApplyRule: (
        condition: ({ form, section }: { form: FormBuilderForm; section: Section }) => boolean,
        rule: () => null | [string, boolean],
      ) => null | [string, boolean];
      validateDynamicDataSource: (actionId: string | undefined, lockedToTemplateId: string | undefined, message: string) => null | [string, boolean];
    },
  ) => readonly (null | [string, boolean] | readonly [string, boolean][])[];
  getPlaceholderData?: (placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) => string | JSX.Element;
};

const getTextValue = (key: string, action: Action, languageCode?: SupportedLanguage): string => {
  return LanguageUtils.getTranslation(key, action?.translations ?? {}, languageCode);
};

const recordWithConstKeys = <T extends object>(value: { [K in keyof T]: ActionTypesInfo }) => value;

const ActionTypes = recordWithConstKeys({
  DateAction: {
    dynamicData: { enabled: true },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/date/DateAction')),
    builderEditor: lazy(() => import('./action-types/date/DateEditor')),
    resourceEditor: lazy(() => import('./action-types/date/DateResourceEditor')),
    previewAction: lazy(() => import('./action-types/date/DatePreview')),
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return (
        <DefaultPlaceholderFormat
          {...placeholderData}
          otherPlaceholders={others}
          answers={placeholderData.answers.map((a) => {
            const date = new Date(a);
            return DateUtils.dateIsValid(date) ? DateUtils.formatDate(date) : a;
          })}
        ></DefaultPlaceholderFormat>
      );
    },
    editorConditionals: [
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.',
        operation: '!EMPTY',
      },
    ],
  },
  SelectListAction: {
    dynamicData: { enabled: true },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/select-list/SelectListAction')),
    builderEditor: lazy(() => import('./action-types/select-list/SelectListEditor')),
    resourceEditor: lazy(() => import('./action-types/select-list/SelectListResourceEditor')),
    previewAction: lazy(() => import('./action-types/select-list/SelectListPreview')),
    conditionalsValueSource: (action) => action.data?.sourceType,
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, validateRisk, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      validateRequired(action?.data?.sourceType, t(VALIDATION_FAIL_PICKLIST_SOURCE_INVALID)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      ...validateRisk(action?.riskEnabled, action?.riskConditions, t(VALIDATION_FAIL_RISK_CONDITION_INCOMPLETE)),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return (
        <DefaultPlaceholderFormat
          {...placeholderData}
          otherPlaceholders={others}
          answers={placeholderData.answers.map((a) => (a.text ? a.text : a))}
        ></DefaultPlaceholderFormat>
      );
    },

    editorConditionals: [
      {
        friendlyName: conditionalKeys.equals,
        path: '$.id',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.notEquals,
        path: '$.id',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.id',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.id',
        operation: '!EMPTY',
      },
    ],
  },
  YesNoAction: {
    dynamicData: { enabled: true },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/yes-no/YesNoAction')),
    builderEditor: lazy(() => import('./action-types/yes-no/YesNoEditor')),
    resourceEditor: lazy(() => import('./action-types/yes-no/YesNoResourceEditor')),
    previewAction: lazy(() => import('./action-types/yes-no/YesNoPreview')),
    conditionalsValueSource: () => 'yesno',
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, validateRisk, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      ...validateRisk(action?.riskEnabled, action?.riskConditions, t(VALIDATION_FAIL_RISK_CONDITION_INCOMPLETE)),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return (
        <DefaultPlaceholderFormat
          {...placeholderData}
          otherPlaceholders={others}
          answers={placeholderData.answers.map((a) => (a.text ? a.text : a))}
        ></DefaultPlaceholderFormat>
      );
    },

    editorConditionals: [
      {
        friendlyName: conditionalKeys.equals,
        path: '$.id',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.notEquals,
        path: '$.id',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.id',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.id',
        operation: '!EMPTY',
      },
    ],
  },
  TextInputAction: {
    dynamicData: { enabled: true },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/text-input/TextInputAction')),
    builderEditor: lazy(() => import('./action-types/text-input/TextInputEditor')),
    resourceEditor: lazy(() => import('./action-types/text-input/TextInputResourceEditor')),
    previewAction: lazy(() => import('./action-types/text-input/TextInputPreview')),
    conditionalsValueSource: () => 'text',
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return <DefaultPlaceholderFormat {...placeholderData} otherPlaceholders={others} answers={placeholderData.answers}></DefaultPlaceholderFormat>;
    },
    editorConditionals: [
      {
        friendlyName: conditionalKeys.charCountEquals,
        path: '$.length',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.charCountNotEquals,
        path: '$.length',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.charCountGreaterThan,
        path: '$.length',
        operation: '>',
      },
      {
        friendlyName: conditionalKeys.charCountGreaterThanOrEquals,
        path: '$.length',
        operation: '>=',
      },
      {
        friendlyName: conditionalKeys.charCountLessThan,
        path: '$.length',
        operation: '<',
      },
      {
        friendlyName: conditionalKeys.charCountLessThanOrEquals,
        path: '$.length',
        operation: '<=',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.',
        operation: '!EMPTY',
      },
    ],
  },
  FileOrLinkUploadAction: {
    dynamicData: { enabled: true },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/file-or-link-upload/FileOrLinkUploadAction')),
    builderEditor: lazy(() => import('./action-types/file-or-link-upload/FileOrLinkUploadEditor')),
    resourceEditor: lazy(() => import('./action-types/file-or-link-upload/FileOrLinkUploadResourceEditor')),
    previewAction: lazy(() => import('./action-types/file-or-link-upload/FileOrLinkUploadPreview')),
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, disallowInPublicSection, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      disallowInPublicSection(action?.id),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return (
        <DefaultPlaceholderFormat
          {...placeholderData}
          otherPlaceholders={others}
          answers={placeholderData.answers}
          wrapper={FilePlaceholderLink}
        ></DefaultPlaceholderFormat>
      );
    },
    editorConditionals: [
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.id',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.id',
        operation: '!EMPTY',
      },
    ],
  },
  SingleFileUploadAction: {
    dynamicData: { enabled: true },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/file-upload/SingleFileUploadAction')),
    builderEditor: lazy(() => import('./action-types/file-upload/SingleFileUploadEditor')),
    resourceEditor: lazy(() => import('./action-types/file-upload/SingleFileUploadResourceEditor')),
    previewAction: lazy(() => import('./action-types/file-upload/SingleFileUploadPreview')),
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, disallowInPublicSection, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      disallowInPublicSection(action?.id),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return (
        <DefaultPlaceholderFormat
          {...placeholderData}
          otherPlaceholders={others}
          answers={placeholderData.answers}
          wrapper={FilePlaceholderLink}
        ></DefaultPlaceholderFormat>
      );
    },
    editorConditionals: [
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.id',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.id',
        operation: '!EMPTY',
      },
    ],
  },
  MultiFileUploadAction: {
    dynamicData: { enabled: true, formatOptions: ['comma', 'bullet'] },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/file-upload/MultiFileUploadAction')),
    builderEditor: lazy(() => import('./action-types/file-upload/MultiFileUploadEditor')),
    resourceEditor: lazy(() => import('./action-types/file-upload/MultiFileUploadResourceEditor')),
    previewAction: lazy(() => import('./action-types/file-upload/MultiFileUploadPreview')),
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, disallowInPublicSection, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      disallowInPublicSection(action?.id),
    ],
    conditionalsValueSource: () => 'text',
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return (
        <DefaultPlaceholderFormat
          {...placeholderData}
          otherPlaceholders={others}
          answers={placeholderData.answers}
          wrapper={FilePlaceholderLink}
        ></DefaultPlaceholderFormat>
      );
    },
    editorConditionals: [
      {
        friendlyName: conditionalKeys.fileCountEquals,
        path: '$.length',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.fileCountNotEquals,
        path: '$.length',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.fileCountGreaterThan,
        path: '$.length',
        operation: '>',
      },
      {
        friendlyName: conditionalKeys.fileCountGreaterThanOrEquals,
        path: '$.length',
        operation: '>=',
      },
      {
        friendlyName: conditionalKeys.fileCountLessThan,
        path: '$.length',
        operation: '<',
      },
      {
        friendlyName: conditionalKeys.fileCountLessThanOrEquals,
        path: '$.length',
        operation: '<=',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.length',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.length',
        operation: '!EMPTY',
      },
    ],
  },
  MultiSelectListAction: {
    hidden: true,
    editAction: lazy(() => import('./action-types/multi-select-list/MultiSelectListAction')),
    builderEditor: lazy(() => import('./action-types/multi-select-list/MultiSelectListEditor')),
    previewAction: lazy(() => import('./action-types/multi-select-list/MultiSelectListPreview')),
    conditionalsValueSource: () => 'text',
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    editorConditionals: [
      {
        friendlyName: conditionalKeys.tagsCountEquals,
        path: '$.length',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.tagsCountNotEquals,
        path: '$.length',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.tagsCountGreaterThan,
        path: '$.length',
        operation: '>',
      },
      {
        friendlyName: conditionalKeys.tagsCountGreaterThanOrEquals,
        path: '$.length',
        operation: '>=',
      },
      {
        friendlyName: conditionalKeys.tagsCountLessThan,
        path: '$.length',
        operation: '<',
      },
      {
        friendlyName: conditionalKeys.tagsCountLessThanOrEquals,
        path: '$.length',
        operation: '<=',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.length',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.length',
        operation: '!EMPTY',
      },
    ],
  },
  PickListAction: {
    dynamicData: { enabled: true, formatOptions: ['comma', 'bullet'] },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/pick-list/PickListAction')),
    builderEditor: lazy(() => import('./action-types/pick-list/PickListEditor')),
    resourceEditor: lazy(() => import('./action-types/pick-list/PickListResourceEditor')),
    previewAction: lazy(() => import('./action-types/pick-list/PickListPreview')),
    conditionalsValueSource: () => 'text',
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, validateRisk, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      validateRequired(getTextValue('buttonText', action), t(VALIDATION_FAIL_BUTTON_TEXT_INVALID)),
      validateRequired(action?.data?.sourceType, t(VALIDATION_FAIL_PICKLIST_SOURCE_INVALID)),
      ...validateRisk(action?.riskEnabled, action?.riskConditions, t(VALIDATION_FAIL_RISK_CONDITION_INCOMPLETE)),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return (
        <DefaultPlaceholderFormat
          {...placeholderData}
          otherPlaceholders={others}
          answers={placeholderData.answers.flat().map((a) => (a.text ? a.text : a))}
        ></DefaultPlaceholderFormat>
      );
    },
    editorConditionals: [
      {
        friendlyName: conditionalKeys.contains,
        path: '$[*].id',
        operation: 'CONTAINS',
        valueSource: (action) => action.data?.sourceType,
      },
      {
        friendlyName: conditionalKeys.notContains,
        path: '$[*].id',
        operation: '!CONTAINS',
        valueSource: (action) => action.data?.sourceType,
      },
      {
        friendlyName: conditionalKeys.tagsCountEquals,
        path: '$.length',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.tagsCountNotEquals,
        path: '$.length',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.tagsCountGreaterThan,
        path: '$.length',
        operation: '>',
      },
      {
        friendlyName: conditionalKeys.tagsCountGreaterThanOrEquals,
        path: '$.length',
        operation: '>=',
      },
      {
        friendlyName: conditionalKeys.tagsCountLessThan,
        path: '$.length',
        operation: '<',
      },
      {
        friendlyName: conditionalKeys.tagsCountLessThanOrEquals,
        path: '$.length',
        operation: '<=',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.length',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.length',
        operation: '!EMPTY',
      },
    ],
  },
  ChildFormListAction: {
    editAction: lazy(() => import('./action-types/child-form/ChildFormListAction')),
    builderEditor: lazy(() => import('./action-types/child-form/ChildFormListEditor')),
    previewAction: lazy(() => import('./action-types/child-form/ChildFormListPreview')),
    conditionalsValueSource: () => 'text',
    actionTitle: (action, languageCode) => getTextValue('title', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, validateAsset, disallowInPublicSection, conditionallyApplyRule }) => [
      validateRequired(getTextValue('title', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      validateRequired(action?.data?.sourceType, t(VALIDATION_FAIL_PICKLIST_SOURCE_INVALID)),
      validateRequired(action?.data?.templateFormId, t(VALIDATION_FAIL_TEMPLATE_SOURCE_INVALID)),
      validateAsset(action?.data?.templateFormId || '', t(VALIDATION_FAIL_TEMPLATE_SOURCE_UNASSOCIATED)),
      disallowInPublicSection(action?.id),
    ],
    editorConditionals: [
      {
        friendlyName: conditionalKeys.subformCountEquals,
        path: '$.length',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.subformCountNotEquals,
        path: '$.length',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.subformCountGreaterThan,
        path: '$.length',
        operation: '>',
      },
      {
        friendlyName: conditionalKeys.subformCountGreaterThanOrEquals,
        path: '$.length',
        operation: '>=',
      },
      {
        friendlyName: conditionalKeys.subformCountLessThan,
        path: '$.length',
        operation: '<',
      },
      {
        friendlyName: conditionalKeys.subformCountLessThanOrEquals,
        path: '$.length',
        operation: '<=',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.length',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.length',
        operation: '!EMPTY',
      },
    ],
  },
  DescriptionAction: {
    editAction: lazy(() => import('./action-types/description/DescriptionAction')),
    builderEditor: lazy(() => import('./action-types/description/DescriptionEditor')),
    previewAction: lazy(() => import('./action-types/description/DescriptionPreview')),
    actionTitle: (action, languageCode) => getTextValue('title', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, conditionallyApplyRule }) => [
      conditionallyApplyRule(
        () => !action?.data?.requiresDescription,
        () => validateRequired(getTextValue('title', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
    ],
  },
  SectionDividerAction: {
    editAction: lazy(() => import('./action-types/divider/SectionDividerAction')),
    builderEditor: lazy(() => import('./action-types/divider/SectionDividerEditor')),
    previewAction: lazy(() => import('./action-types/divider/SectionDividerPreview')),
    actionTitle: () => '',
  },
  ResourcePicklistAction: {
    dynamicData: {
      enabled: true,
      canHaveSecondaryData: true,
      formatOptions: ['comma', 'bullet'],
    },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/resource-picklist/ResourcePicklistAction')),
    builderEditor: lazy(() => import('./action-types/resource-picklist/ResourcePicklistEditor')),
    resourceEditor: lazy(() => import('./action-types/resource-picklist/ResourcePicklistResourceEditor')),
    previewAction: lazy(() => import('./action-types/resource-picklist/ResourcePicklistPreview')),
    conditionalsValueSource: () => 'text',
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (
      action,
      t,
      { validateConditional, validateRequired, validateAsset, disallowInPublicSection, conditionallyApplyRule, validateDynamicDataSource },
    ) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      validateAsset(action?.data?.lockedTemplateId || '', t(VALIDATION_FAIL_TEMPLATE_SOURCE_UNASSOCIATED)),
      disallowInPublicSection(action?.id),
      validateDynamicDataSource(action?.id, action?.data?.lockedTemplateId, t(VALIDATION_FAIL_DYNAMIC_DATA_REQUIRES_TEMPLATE)),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return (
        <DefaultPlaceholderFormat
          {...placeholderData}
          otherPlaceholders={others}
          answers={placeholderData.answers.flat().map((a) => (a.text ? a.text : a))}
        ></DefaultPlaceholderFormat>
      );
    },
    editorConditionals: [
      {
        friendlyName: conditionalKeys.formCountEquals,
        path: '$.length',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.formCountNotEquals,
        path: '$.length',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.formCountGreaterThan,
        path: '$.length',
        operation: '>',
      },
      {
        friendlyName: conditionalKeys.formCountGreaterThanOrEquals,
        path: '$.length',
        operation: '>=',
      },
      {
        friendlyName: conditionalKeys.formCountLessThan,
        path: '$.length',
        operation: '<',
      },
      {
        friendlyName: conditionalKeys.formCountLessThanOrEquals,
        path: '$.length',
        operation: '<=',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.length',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.length',
        operation: '!EMPTY',
      },
    ],
  },
  PreSelectedForm: {
    editAction: lazy(() => import('./action-types/pre-selected-form/PreSelectedFormAction')),
    builderEditor: lazy(() => import('./action-types/pre-selected-form/PreSelectedFormEditor')),
    previewAction: lazy(() => import('./action-types/pre-selected-form/PreSelectedFormPreview')),
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, validateAsset, disallowInPublicSection, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      validateRequired(getTextValue('newFormTitle', action), t(VALIDATION_FAIL_PRESELEDTED_FORM_TITLE_INVALID)),
      validateRequired(action?.data?.templateFormId, t(VALIDATION_FAIL_TEMPLATE_SOURCE_INVALID)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      validateAsset(action?.data?.templateFormId || '', t(VALIDATION_FAIL_TEMPLATE_SOURCE_UNASSOCIATED)),
      disallowInPublicSection(action?.id),
    ],
    editorConditionals: [
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.',
        operation: '!EMPTY',
      },
    ],
  },
  Address: {
    dynamicData: { enabled: true },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/address/AddressAction')),
    builderEditor: lazy(() => import('./action-types/address/AddressEditor')),
    resourceEditor: lazy(() => import('./action-types/address/AddressResourceEditor')),
    previewAction: lazy(() => import('./action-types/address/AddressPreview')),
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return (
        <DefaultPlaceholderFormat
          {...placeholderData}
          otherPlaceholders={others}
          answers={placeholderData.answers.map((a) => (a.address ? a.address : a))}
        ></DefaultPlaceholderFormat>
      );
    },

    editorConditionals: [
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.address',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.address',
        operation: '!EMPTY',
      },
    ],
  },
  EmailInputAction: {
    dynamicData: { enabled: true, formatOptions: ['link', 'plain'] },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/email-input/EmailInputAction')),
    builderEditor: lazy(() => import('./action-types/email-input/EmailInputEditor')),
    resourceEditor: lazy(() => import('./action-types/email-input/EmailInputResourceEditor')),
    previewAction: lazy(() => import('./action-types/email-input/EmailInputPreview')),
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return (
        <DefaultPlaceholderFormat
          {...placeholderData}
          otherPlaceholders={others}
          answers={placeholderData.answers}
          wrapper={placeholderData.dataFormat === 'link' ? MailtoPlaceholderLink : 'span'}
        ></DefaultPlaceholderFormat>
      );
    },

    editorConditionals: [
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.',
        operation: '!EMPTY',
      },
    ],
  },
  TickboxAction: {
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/tickbox/TickboxAction')),
    builderEditor: lazy(() => import('./action-types/tickbox/TickboxEditor')),
    resourceEditor: lazy(() => import('./action-types/tickbox/TickboxResourceEditor')),
    previewAction: lazy(() => import('./action-types/tickbox/TickboxPreview')),
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateRequired, validateConditional, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
    ],
    conditionalsValueSource: () => 'tickbox',
    editorConditionals: [
      {
        friendlyName: conditionalKeys.equals,
        operation: '=',
        path: '$.checked',
      },
      {
        friendlyName: conditionalKeys.notEquals,
        operation: '!=',
        path: '$.checked',
      },
    ],
  },
  ViewAction: {
    editAction: lazy(() => import('./action-types/view/ViewAction')),
    builderEditor: lazy(() => import('./action-types/view/ViewEditor')),
    previewAction: lazy(() => import('./action-types/view/ViewAction')),
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
    ],
  },
  PhoneNumberAction: {
    dynamicData: { enabled: true },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/phone-number/PhoneNumberAction')),
    builderEditor: lazy(() => import('./action-types/phone-number/PhoneNumberEditor')),
    resourceEditor: lazy(() => import('./action-types/phone-number/PhoneNumberResourceEditor')),
    previewAction: lazy(() => import('./action-types/phone-number/PhoneNumberPreview')),
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    conditionalsValueSource: () => 'text',
    validate: (action, t, { validateConditional, validateRequired, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return (
        <DefaultPlaceholderFormat
          {...placeholderData}
          otherPlaceholders={others}
          answers={placeholderData.answers.map((x) => x.phoneNumber)}
          wrapper={PhoneNumberPlaceholderWrapper}
        ></DefaultPlaceholderFormat>
      );
    },

    editorConditionals: [
      {
        friendlyName: conditionalKeys.equals,
        path: '$.phoneNumber',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.notEquals,
        path: '$.phoneNumber',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.contains,
        path: '$.phoneNumber',
        operation: 'CONTAINS',
      },
      {
        friendlyName: conditionalKeys.notContains,
        path: '$.phoneNumber',
        operation: '!CONTAINS',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.phoneNumber',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.phoneNumber',
        operation: '!EMPTY',
      },
    ],
  },
  NumberInputAction: {
    dynamicData: { enabled: true },
    resourceFriendly: true,
    resourceAlignment: 'right',
    editAction: lazy(() => import('./action-types/number-input/NumberInputAction')),
    builderEditor: lazy(() => import('./action-types/number-input/NumberInputEditor')),
    resourceEditor: lazy(() => import('./action-types/number-input/NumberInputResourceEditor')),
    previewAction: lazy(() => import('./action-types/number-input/NumberInputPreview')),
    conditionalsValueSource: () => 'text',
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, conditionallyApplyRule, validateRisk }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      ...validateRisk(action?.riskEnabled, action?.riskConditions, t(VALIDATION_FAIL_RISK_CONDITION_INCOMPLETE)),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return <DefaultPlaceholderFormat {...placeholderData} otherPlaceholders={others} answers={placeholderData.answers}></DefaultPlaceholderFormat>;
    },
    editorConditionals: [
      {
        friendlyName: conditionalKeys.equals,
        path: '$.',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.notEquals,
        path: '$.',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.greaterThan,
        path: '$.',
        operation: '>',
      },
      {
        friendlyName: conditionalKeys.greaterThanOrEquals,
        path: '$.',
        operation: '>=',
      },
      {
        friendlyName: conditionalKeys.lessThan,
        path: '$.',
        operation: '<',
      },
      {
        friendlyName: conditionalKeys.lessThanOrEquals,
        path: '$.',
        operation: '<=',
      },
      {
        friendlyName: conditionalKeys.contains,
        path: '$.',
        operation: 'CONTAINS',
      },
      {
        friendlyName: conditionalKeys.notContains,
        path: '$.',
        operation: '!CONTAINS',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.',
        operation: '!EMPTY',
      },
    ],
  },
  CurrencyInputAction: {
    dynamicData: { enabled: true },
    resourceFriendly: true,
    resourceAlignment: 'right',
    editAction: lazy(() => import('./action-types/currency-input/CurrencyInputAction')),
    builderEditor: lazy(() => import('./action-types/currency-input/CurrencyInputEditor')),
    resourceEditor: lazy(() => import('./action-types/currency-input/CurrencyInputResourceEditor')),
    previewAction: lazy(() => import('./action-types/currency-input/CurrencyInputPreview')),
    conditionalsValueSource: () => 'text',
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, conditionallyApplyRule, validateRisk }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      ...validateRisk(action?.riskEnabled, action?.riskConditions, t(VALIDATION_FAIL_RISK_CONDITION_INCOMPLETE)),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return (
        <DefaultPlaceholderFormat
          {...placeholderData}
          otherPlaceholders={others}
          answers={placeholderData.answers.map((x) => `${x.currencyCode} ${x.amount}`)}
          wrapper={CurrencyPlaceholderWrapper}
        ></DefaultPlaceholderFormat>
      );
    },
    editorConditionals: [
      {
        friendlyName: conditionalKeys.equals,
        path: '$.amount',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.notEquals,
        path: '$.amount',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.greaterThan,
        path: '$.amount',
        operation: '>',
      },
      {
        friendlyName: conditionalKeys.greaterThanOrEquals,
        path: '$.amount',
        operation: '>=',
      },
      {
        friendlyName: conditionalKeys.lessThan,
        path: '$.amount',
        operation: '<',
      },
      {
        friendlyName: conditionalKeys.lessThanOrEquals,
        path: '$.amount',
        operation: '<=',
      },
      {
        friendlyName: conditionalKeys.contains,
        path: '$.amount',
        operation: 'CONTAINS',
      },
      {
        friendlyName: conditionalKeys.notContains,
        path: '$.amount',
        operation: '!CONTAINS',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.amount',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.amount',
        operation: '!EMPTY',
      },
    ],
  },
  LinkAction: {
    dynamicData: { enabled: true },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/link/LinkAction')),
    builderEditor: lazy(() => import('./action-types/link/LinkEditor')),
    resourceEditor: lazy(() => import('./action-types/link/LinkResourceEditor')),
    previewAction: lazy(() => import('./action-types/link/LinkPreview')),
    conditionalsValueSource: () => 'text',
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, disallowInPublicSection, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      disallowInPublicSection(action?.id),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return <DefaultPlaceholderFormat {...placeholderData} otherPlaceholders={others} answers={placeholderData.answers}></DefaultPlaceholderFormat>;
    },
    editorConditionals: [
      {
        friendlyName: conditionalKeys.equals,
        path: '$.',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.notEquals,
        path: '$.',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.contains,
        path: '$.',
        operation: 'CONTAINS',
      },
      {
        friendlyName: conditionalKeys.notContains,
        path: '$.',
        operation: '!CONTAINS',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.',
        operation: '!EMPTY',
      },
    ],
  },
  DocumentReferenceAction: {
    dynamicData: {
      enabled: true,
      formatOptions: ['comma', 'bullet'],
    },
    resourceFriendly: true,
    editAction: lazy(() => import('./action-types/document-reference/DocumentReferenceAction')),
    builderEditor: lazy(() => import('./action-types/document-reference/DocumentReferenceEditor')),
    //resourceEditor: lazy(() => import('./action-types/resource-picklist/DocumentReferenceResourceEditor')),
    previewAction: lazy(() => import('./action-types/document-reference/DocumentReferencePreview')),
    conditionalsValueSource: () => 'text',
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, disallowInPublicSection, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.required, t(VALIDATION_FAIL_REQUIRED_CONDITION_INCOMPLETE)),
      ),
      conditionallyApplyRule(
        ({ form }) => form.type !== FormType.Resource,
        () => validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
      ),
      disallowInPublicSection(action?.id),
    ],
    getPlaceholderData(placeholderData: ActionPlaceholderData, others: Record<string, ActionPlaceholderData>) {
      return <DefaultPlaceholderFormat {...placeholderData} otherPlaceholders={others} answers={placeholderData.answers}></DefaultPlaceholderFormat>;
    },
    editorConditionals: [
      {
        friendlyName: conditionalKeys.documentCountEquals,
        path: '$.length',
        operation: '=',
      },
      {
        friendlyName: conditionalKeys.documentCountNotEquals,
        path: '$.length',
        operation: '!=',
      },
      {
        friendlyName: conditionalKeys.documentCountGreaterThan,
        path: '$.length',
        operation: '>',
      },
      {
        friendlyName: conditionalKeys.documentCountGreaterThanOrEquals,
        path: '$.length',
        operation: '>=',
      },
      {
        friendlyName: conditionalKeys.documentCountLessThan,
        path: '$.length',
        operation: '<',
      },
      {
        friendlyName: conditionalKeys.documentCountLessThanOrEquals,
        path: '$.length',
        operation: '<=',
      },
      {
        friendlyName: conditionalKeys.isEmpty,
        path: '$.length',
        operation: 'EMPTY',
      },
      {
        friendlyName: conditionalKeys.isNotEmpty,
        path: '$.length',
        operation: '!EMPTY',
      },
    ],
  },
  AdHocFieldsAction: {
    editAction: lazy(() => import('./action-types/ad-hoc-fields/AdHocFieldsAction')),
    builderEditor: lazy(() => import('./action-types/ad-hoc-fields/AdHocFieldsEditor')),
    previewAction: lazy(() => import('./action-types/ad-hoc-fields/AdHocFieldsPreview')),
    customActionWrapper: 'div',
    actionTitle: (action, languageCode) => getTextValue('question', action, languageCode),
    validate: (action, t, { validateConditional, validateRequired, conditionallyApplyRule }) => [
      validateRequired(getTextValue('question', action), t(VALIDATION_FAIL_TITLE_REQUIRED)),
      conditionallyApplyRule(
        () => action?.data?.requiresDescription,
        () => validateRequired(getTextValue('description', action), t(VALIDATION_FAIL_DESCRIPTION_REQUIRED)),
      ),
      conditionallyApplyRule(
        () => action?.data?.requiresHelp,
        () => validateRequired(getTextValue('help', action), t(VALIDATION_FAIL_HELP_REQUIRED)),
      ),
      validateConditional(action?.visible, t(VALIDATION_FAIL_VISIBILITY_CONDITION_INCOMPLETE)),
    ],
  },
});

const fakeGlobalState = {
  ids: Object.entries(ActionTypes).map(([type, _]) => ({ type, id: uuid() })),
  subscribers: [] as any[],
};
export const useActionTypeBuilderIdGenerator = () => {
  const [ids, setIds] = useState(fakeGlobalState.ids);

  useEffect(() => {
    fakeGlobalState.subscribers.push(setIds);

    return () => {
      fakeGlobalState.subscribers = fakeGlobalState.subscribers.filter((x) => x !== setIds);
    };
  }, []);

  const actions = useMemo(
    () => ({
      getId(type: string) {
        return ids.find((x) => x.type === type)?.id;
      },
      generateNewIds() {
        fakeGlobalState.ids = Object.entries(ActionTypes).map(([type, _]) => ({ type, id: uuid() }));
        for (const subscriber of fakeGlobalState.subscribers) {
          subscriber(fakeGlobalState.ids);
        }
      },
    }),
    [ids],
  );

  return { ids, ...actions };
};

export default ActionTypes;

export type ActionTypeNames = keyof typeof ActionTypes;
